<template>
  <div>
    <div class="container-payee-manager container-fluid mt--6">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row justify-content-md-center">
            <div class="col-sm-12 col-md-8 text-center">
              <el-input
                class="form-control-alternative input-btn-group-primary"
                placeholder="Enter Payee Name"
                v-model="search"
              >
                <template #append>
                  <el-button
                    class="btn"
                    icon="el-icon-search"
                    @click="searchPayee()"
                    >Search</el-button
                  >
                </template>
              </el-input>
            </div>
            <div class="col-sm-12 mt-3" v-loading="searchLoading">
              <el-table
                size="mini"
                v-show="!isMobile && searchTable.length > 0"
                :data="searchTable"
                border
                style="width: 100%"
              >
                <el-table-column
                  prop="currency"
                  label="Cuurency"
                  header-align="center"
                  width="80"
                  align="center"
                  fixed
                >
                  <template slot-scope="scope">
                    {{
                      scope.row.atwcurrency === ""
                        ? scope.row.currency
                        : scope.row.atwcurrency
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="Payee"
                  header-align="center"
                  width="180"
                  fixed
                ></el-table-column>
                <el-table-column
                  prop="accountnumber"
                  label="Acc No./ IBAN"
                  header-align="center"
                  align="center"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="sortcode"
                  label="Sort Code / BIC"
                  header-align="center"
                  align="center"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="currency"
                  label="Swift"
                  header-align="center"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope">
                    {{ scope.row.currency === "SWT" ? "Yes" : "-" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="reference"
                  label="Reference"
                  header-align="center"
                ></el-table-column>
                <el-table-column
                  prop="status"
                  label="Status"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.status == 'active'" size="mini">
                      {{ scope.row.status.toUpperCase() }}
                    </el-tag>
                    <el-tag
                      v-if="scope.row.status == 'pending'"
                      size="mini"
                      type="warning"
                    >
                      {{ scope.row.status.toUpperCase() }}
                    </el-tag>
                    <el-tag
                      v-if="scope.row.status == 'reject'"
                      size="mini"
                      type="danger"
                    >
                      {{ scope.row.status.toUpperCase() }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="uid"
                  label="Action"
                  header-align="center"
                  width="120"
                >
                  <template slot-scope="scope">
                    <el-button
                    v-if="!['pending', 'reject'].includes(scope.row.status)"
                      type="success"
                      class="btn-mini"
                      @click="
                        makePayment(
                          scope.row.uid,
                          scope.row.currency,
                          scope.row.atwcurrency
                        )
                      "
                      >Pay</el-button
                    >
                    <el-button
                      type="warning"
                      class="btn-mini"
                      @click="editPayee(scope.row.uid, scope.row.currency)"
                      >Edit</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>

              <div class="list-group m--2" v-if="isMobile">
                <div
                  class="list-group-item"
                  v-for="(item, index) of searchTable"
                  :key="index"
                >
                  <div class="payee-content">
                    <div class="payee-title">
                      <b>{{ item.name }}</b>
                    </div>
                    <div class="payee-currency">
                      <b>Currency : </b
                      >{{
                        item.atwcurrency === ""
                          ? item.currency
                          : item.atwcurrency
                      }}
                    </div>
                    <div class="payee-acc-number">
                      <b
                        >{{
                          ["gbp", "thb"].includes(SwiftType)
                            ? "Acc No."
                            : "IBAN"
                        }}
                        :</b
                      >
                      {{ item.accountnumber }}
                    </div>
                    <div class="payee-sortcode" v-if="item.sortcode">
                      <b>{{ SwiftType === "gbp" ? "Sort Code" : "BIC" }} :</b>
                      {{ item.sortcode }}
                    </div>
                    <div class="payee-reference">
                      <b>Reference :</b> {{ item.reference }}
                    </div>
                  </div>
                  <div class="text-right">
                    <el-button
                      type="success"
                      class="btn-mini"
                      @click="
                        makePayment(item.uid, item.currency, item.atwcurrency)
                      "
                       v-if="!['pending', 'reject'].includes(item.status)"
                      >Pay</el-button
                    >
                    <el-button
                      type="warning"
                      class="btn-mini"
                      @click="editPayee(item.uid, item.currency)"
                      >Edit</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-9 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">Manage Payees</h3>
            </div>

            <div class="card-body">
              <div class="row">
                <div
                  class="col-md-12 col-lg-12 col-xl-6 mb-3 d-grid"
                  v-for="currency of Object.keys(payeeType)"
                  :key="currency"
                >
                  <div
                    :class="`card card-stats ${getBackground(
                      currency
                    )} card-hover`"
                  >
                    <!---->
                    <div
                      class="card-body p-4 min-vh-8"
                      @click="
                        payeeView(
                          currency,
                          payeeType[currency].status,
                          payeeType[currency].type
                        )
                      "
                    >
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div class="col">
                              <h5
                                :class="`card-title text-uppercase mb-0 ${getTextColor(
                                  currency
                                )}`"
                              >
                                {{ payeeType[currency].text }}
                              </h5>
                              <span :class="`text-sm ${getTextColor(currency)}`"
                                >Manage your
                                {{ payeeType[currency].text }} payee list.</span
                              >
                            </div>
                            <div class="col-auto">
                              <span
                                :class="`h2 font-weight-bold mb-0 ${getTextColor(
                                  currency
                                )}`"
                                >{{
                                  payeeType[currency].status === "ready"
                                    ? payeeType[currency].payee_number
                                    : "Coming"
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <!---->
                      </div>
                    </div>
                    <!----><!---->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <el-dropdown
                class="btn-block btn-shadow"
                trigger="click"
                @command="addPayeeCommand"
              >
                <el-button type="primary" class="btn-block">
                  Add Payee<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="currency of Object.keys(payeeType)"
                    :key="currency"
                    :command="`${currency}/${payeeType[currency].type}`"
                    :disabled="payeeType[currency].status != 'ready'"
                    >{{ payeeType[currency].text }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>

              <Shortcut class="mt-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PayeeApi from "@/api/payee";
import { isMobile } from "@/utils/helpers";
import Shortcut from "@/components/MenuShortcut.vue";
export default {
  components: {
    Shortcut,
  },
  computed: {
    getname() {
      return this.$store.getters.name;
    },
    multiaccount() {
      return this.$store.getters.multiaccount;
    },
    isBusiness() {
      return this.$store.getters.business;
    },
    isMobile() {
      return isMobile();
    },
  },
  data() {
    return {
      search: "",
      searchTable: [],
      searchLoading: false,
      payeeType: [],
    };
  },
  methods: {
    getPayee() {
      PayeeApi.getPayee().then(({ result, data }) => {
        if (result) {
          this.payeeType = data;
        }
      });
    },
    searchPayee() {
      this.searchLoading = true;
      this.searchTable = [];
      PayeeApi.search(this.search).then(({ data }) => {
        this.searchLoading = false;
        this.searchTable = data;
      });
    },
    payeeView(currency, status, type) {
      if (status === "ready") {
        this.$router.push({ path: `/payee/view/${currency}/${type}` });
      }
    },
    editPayee(uid, currency) {
      this.$router.push(`/payee/edit/${currency.toLowerCase()}/${uid}`);
    },
    makePayment(uid, currency, atwcurrency) {
      let queryCuurency = "";
      if (atwcurrency) {
        queryCuurency = `&currency=${atwcurrency}`;
      }
      this.$router.push(
        `/payment/${currency.toLowerCase()}?payee=${uid}${queryCuurency}`
      );
    },
    getTextColor(currency, type) {
      if (this.payeeType[currency].is_swift) {
        if (type === 1) {
          return "text-swift1";
        } else {
          return "text-swift2";
        }
      }
      return "text-white";
    },
    getBackground(currency) {
      if (this.payeeType[currency].is_swift) {
        return "bg-gold";
      }

      if (["usd", "localatw"].includes(currency)) {
        return "bg-success text";
      }

      return "bg-primary";
    },
    addPayeeCommand(commands) {
      this.$router.push({ path: `/payee/add/${commands}` });
    },
  },
  created() {
    this.getPayee();
  },
};
</script>
<style lang="scss">
.container-payee-manager {
  .payee-title {
    font-size: 12px;
    color: #004b6a;
  }

  .payee-content {
    padding: 5px 10px;
  }

  .payee-currency,
  .payee-acc-number,
  .payee-sortcode,
  .payee-reference {
    font-size: 12px;
    color: #467c94;
  }
}
</style>
